import SocialListItem from "../social-list-item";
import {
  FacebookShareButton,
  TelegramShareButton,
  TwitterShareButton,
} from "react-share";

const SocialList = ({ shareLink }) => (
  <div className="flex items-center justify-between">
    <TwitterShareButton url={shareLink} className="!pb-10 !px-2.5">
      <SocialListItem social="Twitter" />
    </TwitterShareButton>
    <TelegramShareButton url={shareLink} className="!pb-10 !px-2.5">
      <SocialListItem social="Telegram" />
    </TelegramShareButton>
    <FacebookShareButton url={shareLink} className="!pb-10 !px-2.5">
      <SocialListItem social="Facebook" />
    </FacebookShareButton>
  </div>
);

export default SocialList;
