import { useState } from "react";
import AllIcons from "~components/zui/components/all-icons";
import P from "~ui/typography/p";

const CopyButton = ({
  textToCopy,
  title = "",
  containerClassName,
  btnColor = "white",
}) => {
  const [copied, setCopied] = useState(false);

  const handleClick = () => {
    if (!window.isSecureContext) {
      alert("Copying is not supported in your browser");

      return;
    }
    window.navigator.clipboard.writeText(textToCopy);
    setCopied(true);
    setTimeout(() => setCopied(false), 7000);
  };

  return (
    <div
      className={`flex items-center cursor-pointer ${containerClassName}`}
      onClick={handleClick}
    >
      <AllIcons
        name={copied ? "Check" : "Copy"}
        className={`@wh w-5 duration-200 ${
          copied
            ? "@ttc text-green-500"
            : `@ttc text-${btnColor} hover:text-opacity-70`
        }`}
      />
      <div className={"w-0"}>&nbsp;</div>
      {title && <P>{copied ? "Done!" : title}</P>}
    </div>
  );
};

export default CopyButton;
