import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { setSubscribeModal } from "~store/modals";

const SubscribedButton = ({ profile }) => {
  const dispatch = useDispatch();
  const [isHover, setIsHover] = useState(false);

  return (
    <div className={"w-full lg:w-fit"}>
      <button
        onClick={() => dispatch(setSubscribeModal({ profile, show: true }))}
        className={
          "font-clash font-semibold text-md text-white px-5 py-4 md:px-8 duration-200 rounded-full cursor-pointer w-full lg:w-[160px] mr-0 CustomButton"
        }
        onMouseOver={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
      >
        <span className="text-white">{isHover ? "Upgrade" : "Subscribed"}</span>
      </button>
    </div>
  );
};

export default SubscribedButton;
