import P from "~ui/typography/p";

export default function RowText({ title, text }) {
  return (
    <div className="flex flex-col md:flex-row items-center md:ml-5">
      <P className="@or order-2 md:order-1 @ttc text-zinc-base @fts text-xs md:text-sm @mn mr-1.5">
        {title}
      </P>
      <P className="@or order-1 md:order-2 @ftf font-clash @ftw font-semibold @fts text-md md:text-[22px] @mn mb-1 md:mb-0">
        {text}
      </P>
    </div>
  );
}
