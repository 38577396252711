import CardBox from "~components/ui/card-box";
import Button from "~ui/button";
import CopyText from "~ui/interactions/copy-text";
import { getSocialImagePath } from "~utils/vanilla/formatters/get-social-image-path";
import Avatar from "~components/ui/avatar";
import P from "~components/ui/typography/p";
import {
  useCreateReferralLinkMutation,
  useGetReferralLinkQuery,
} from "~services/backend/referral-links";

// TODO: ---> utils
const url = typeof window !== "undefined" ? window?.location.origin : "";

const ReferralLink = ({ profile, contextProfile }) => {
  const { data: referralLink } = useGetReferralLinkQuery(
    {
      userId: contextProfile.id,
      authorId: profile.id,
    },
    { skip: !contextProfile.id || !profile.id },
  );

  const [createReferralLink] = useCreateReferralLinkMutation();

  const handleClick = async () => {
    if (referralLink) return;

    await createReferralLink(profile.id);
  };

  const preparedImage = profile.image
    ? getSocialImagePath(profile.image)
    : null;

  return (
    <CardBox className="divide-y divide-true-gray-150 @bdc bg-zinc-900 @mn mb-5 @pg p-[30px]">
      <div className="">
        <h5 className="text-white overflow-hidden truncate font-clash font-semibold mb-5">
          Your referral link to user:
        </h5>
        <div className="relative flex flex-col md:flex-row mb-7">
          <div className="relative flex items-center w-full mb-4 md:mb-0">
            <Avatar
              className="@dy hidden lg:flex @wh !w-[46px] @ht !h-[46px] mr-3 @brc border-white"
              src={preparedImage}
            />
            {referralLink ? (
              <CopyText
                title={`${url}/${profile.username}?r=${
                  contextProfile.id || ""
                }&u=${profile.id || ""}`}
              />
            ) : (
              <Button
                onClick={handleClick}
                variant="rounded"
                className="@wh w-full @tta text-center @bdc bg-white hover:bg-lime-base @ttc text-zinc-900"
              >
                Generate Link
              </Button>
            )}
          </div>
        </div>
        <Button
          href="/help-center#referral-program-for-fans-traffic-managers"
          className="@ttc text-white @ttoy text-opacity-70 hover:text-opacity-100 @wh w-fit @tndn duration-200"
        >
          <P>What is referral program?</P>
        </Button>
      </div>
    </CardBox>
  );
};

export default ReferralLink;
