import React from "react";
import CardBox from "~components/ui/card-box";
import Button from "~ui/button";
import CopyText from "~ui/interactions/copy-text";
import P from "~components/ui/typography/p";

const PlatformReferral = ({ contextProfile }) => {
  const { address } = contextProfile;

  if (!address) return null;
  return (
    <CardBox className="@wh w-full @bdc bg-white @brw border border-[#F5F5F5] @pg p-5 lg:p-[30px] @mn mb-5 overflow-hidden shadow-small">
      <div className="">
        <div className="flex items-center justify-start mb-5">
          <img
            className="w-7 h-7 object-center mr-1"
            src="/assets/images/coin.png"
          />
          <h5 className="text-black font-clash font-semibold whitespace-nowrap">
            Invite authors and
          </h5>
          &nbsp;
          <h5 className="text-white font-clash font-semibold text-rainbow-gradient whitespace-nowrap truncate">
            start earning!
          </h5>
        </div>
        <div className="relative flex flex-col md:flex-row mb-7">
          <div className="relative flex items-center w-full mb-4 md:mb-0">
            <CopyText
              className={
                "!bg-stone-base !rounded-full !p-3 !text-zinc-900 !font-medium relative"
              }
              inputClassName={"!w-[90%] text-left"}
              btnColor={"zinc-900"}
              btnClassName={"!top-3 bg-stone-base"}
              textToCopy={`https://subclub.me/?ar=${address}`}
              title={`subclub.me/?ar=${address}`}
            />
          </div>
        </div>
        <Button
          href="/help-center#referral-program-for-authors"
          className="@ttc text-zinc-base @ttoy text-opacity-70 hover:text-opacity-100 @wh w-fit @tndn duration-200 text-center"
        >
          <P>Learn more about inviting authors</P>
        </Button>
      </div>
    </CardBox>
  );
};

export default PlatformReferral;
