import { useSpring, animated, config } from "@react-spring/web";

const AnimationWrapper = ({ show, onClick, children }) => {
  const cardAnimation = useSpring({
    opacity: show ? "1" : "0.6",
    transform: show ? "scale(1)" : "scale(0.97)",
    config: { ...config.default },
    delay: 70,
  });

  return (
    <animated.div
      onClick={onClick}
      style={cardAnimation}
      className={`${show ? "" : "hidden"}`}
    >
      {children}
    </animated.div>
  );
};

export default AnimationWrapper;
