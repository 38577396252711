import { useMemo, useState } from "react";
import P from "~ui/typography/p";
import H4 from "~ui/typography/h4";
import H3 from "~ui/typography/h3";
import Button from "~components/ui/button";
import AllIcons from "~components/zui/components/all-icons";
import useProfile from "~services/backend/profiles/useProfile";
import { useSubscriptions } from "src/hooks/web3/use-contract";
import { formatAddress } from "~utils/transformers";

import RowText from "./row-text";
import SubscribeButton from "./SubscribeButton";
import DonateButton from "./DonateButton";
import ShareButton from "./ShareButton";
import SubscribedButton from "./SubscribedButton";
import { useRouter } from "next/router";
import {
  useCreateFollowingMutation,
  useDeleteFollowingMutation,
} from "~services/backend/followings";
import { useGetSubscriptionLevelsQuery } from "~services/backend/subscription-levels";
import Link from "next/link";

// TODO: --> utils.js? for resuse
const url = typeof window !== "undefined" ? window?.location.origin : "";

const ProfileInfo = ({ profile, posts, disableButtons, isMyProfile }) => {
  const { username, address, bio, isVerified, followers, chat } = profile || {};

  const { profile: contextProfile } = useProfile();
  const { pathname } = useRouter();

  const [createFollowing] = useCreateFollowingMutation();
  const [deleteFollowing] = useDeleteFollowingMutation();

  const isFollowed = useMemo(() => {
    if (!contextProfile) return false;

    return !!followers?.find((f) => f.user?.id === contextProfile.id);
  }, [followers, contextProfile]);

  const { data: levels = [] } = useGetSubscriptionLevelsQuery(
    address?.toLowerCase(),
  );

  const filteredLevels = useMemo(
    () => levels.filter((level) => level.isActive),
    [levels],
  );

  const { currentSubscriptions } = useSubscriptions(address);

  const handleFollowButton = async () => {
    if (!isFollowed) {
      await createFollowing({
        authorId: profile.id,
      });
      // TODO: re-fetch profile
    } else {
      const subId = followers?.find((f) => f.user.id === contextProfile.id)?.id;
      deleteFollowing(subId);
      // TODO: re-fetch profile
    }
  };

  const subsCount = useMemo(
    () => ({
      subscribers: followers?.filter((follow) => !!follow.tokenId).length || 0,
      followers: followers?.filter((follow) => !follow.tokenId).length || 0,
    }),
    [followers],
  );

  const [fullBio, setFullBio] = useState(false);

  const bioLines = bio?.split("\n") || [];

  const renderBio = useMemo(() => {
    // if (pathname === `/profile`) return `Your wallet is not connected...`;

    if (fullBio || bioLines.length < 2) return bio || null;

    const cuttedBioLines = bio?.split("\n").splice(0, 1);
    const cuttedBio = cuttedBioLines.join("\n");

    return cuttedBio || null;
  }, [bio, pathname, fullBio]);

  const showEditButton = !!isMyProfile;
  const showFollowButton =
    !isMyProfile && contextProfile && !currentSubscriptions?.length;
  const showSubscribeButton =
    !isMyProfile &&
    contextProfile &&
    !!filteredLevels.length &&
    !currentSubscriptions?.length;
  const showSubscribedButton =
    !isMyProfile && contextProfile && !!currentSubscriptions?.length;
  const showDonateButton = !isMyProfile && contextProfile && profile?.address;
  const showOpenChatButton = !isMyProfile && profile?.chatEnabled;

  return (
    <div className="flex flex-col lg:flex-row items-center md:items-start">
      <div className="flex flex-col w-full lg:w-1/2 items-center lg:items-start mb-5">
        {profile && (
          <div className="flex flex-col lg:flex-row mb-5 lg:mb-2.5 items-center lg:gap-2">
            <div className="flex items-center mx-auto lg:mx-0">
              <H3 className="@ftf font-clash @ftw font-semibold @fts text-22px @ttc text-zinc-900">
                @{formatAddress(username)}
              </H3>
              {isVerified ? (
                <AllIcons
                  className={"w-4 lg:w-5 text-pink-base ml-1"}
                  name="Confirmed"
                />
              ) : null}
            </div>
            <div className="flex mx-auto md:mx-0">
              <H4 className="@fts text-[14px] @ttc text-zinc-base">
                {formatAddress(address)}
              </H4>
            </div>
          </div>
        )}

        {renderBio && (
          <P className="@fts text-[16px] @ttc text-zinc-base @leh leading-20px @tta text-center md:text-left whitespace-pre-line mt-2">
            {renderBio}
          </P>
        )}

        {bioLines.length > 1 && (
          <Button
            onClick={() => setFullBio((fb) => !fb)}
            variant="text"
            className="@ttc text-pink-base hover:text-zinc-900 mt-1"
          >
            {fullBio ? "Show less" : "Show more"}
          </Button>
        )}
      </div>
      <div className="w-full lg:w-1/2 flex flex-col justify-between items-start lg:items-end">
        <div className="w-full lg:w-fit flex justify-between my-10 md:my-0 md:mb-5 @ttc text-zinc-900">
          <RowText title={"Publications"} text={posts?.length || 0} />
          <RowText title={"Subscribers"} text={subsCount.subscribers} />
          <RowText title={"Followers"} text={subsCount.followers} />
        </div>

        {pathname !== "/profile" && !disableButtons && (
          <div className="flex w-full flex-col lg:flex-row text-center gap-5 lg:justify-end">
            {showEditButton && (
              <Button
                href="/settings/account"
                variant="rounded"
                className="@mn mr-0 @wh w-full lg:w-fit @tta text-center @pg px-5 py-4 md:px-8"
              >
                Edit Profile
              </Button>
            )}

            {showSubscribeButton && (
              <SubscribeButton profile={profile} title={"Subscribe"} />
            )}

            {showSubscribedButton && <SubscribedButton profile={profile} />}

            {showOpenChatButton && (
              <div className="cursor-pointer h-full flex-shrink-0">
                <Link
                  href={
                    chat ? `/chats/${chat.id}` : `/chats?user=${profile.id}`
                  }
                >
                  <a className="block font-clash font-semibold text-md text-zinc-900 hover:text-white hover:bg-zinc-900 px-2 py-[15px] bg-white border border-zinc-900 duration-200 rounded-full cursor-pointer w-full lg:w-[140px] mr-0 text-center ">
                    Open chat
                  </a>
                </Link>
              </div>
            )}

            {showFollowButton && (
              <Button
                onClick={handleFollowButton}
                variant="rounded"
                className="@wh w-full lg:w-[140px] @bdc bg-zinc-900 border border-zinc-900 hover:bg-white @ttc text-white hover:text-zinc-900 @pg px-5 py-[15px] md:px-8 @mn mr-0"
              >
                {isFollowed ? "Unfollow" : "Follow"}
              </Button>
            )}

            {showDonateButton && <DonateButton address={address} />}

            <ShareButton shareLink={`${url}/${username}`} />
          </div>
        )}
      </div>
    </div>
  );
};

export default ProfileInfo;
