import AllIcons from "~components/zui/components/all-icons";

const SocialListItem = ({ social }) => {
  return (
    <div className="rounded-full shadow-md border border-true-gray-100 dark:border-true-gray-450 p-5 w-16 h-16 flex items-center justify-center cursor-pointer">
      <AllIcons
        name={social}
        className="@ttc text-black dark:text-white @wh w-6 @ht h-6 group-hover:text-blue-primary @tndn duration-200 stroke-black fill-black"
      />
    </div>
  );
};

export default SocialListItem;
