import React from "react";

import AllIcons from "~components/ui/all-icons";
import Button from "~components/ui/button";
import { useDispatch } from "react-redux";
import { setSubscribeModal } from "~store/modals";

const SubscribeButton = ({ title, buttonClasses, profile }) => {
  const dispatch = useDispatch();

  return (
    <div className={"@wh w-full lg:w-fit @ht h-auto"}>
      <Button
        onClick={() => dispatch(setSubscribeModal({ profile, show: true }))}
        variant="rounded"
        className={`@wh w-full lg:w-fit @mn mr-0 @bdc bg-lime-base hover:bg-zinc-900 @ttc text-zinc-900 hover:text-white @pg px-4 py-4 flex items-center justify-center ${buttonClasses}`}
      >
        <AllIcons className="w-4 h-4 mr-2" name="Dollar" />
        {title}
      </Button>
    </div>
  );
};

export default SubscribeButton;
