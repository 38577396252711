import Image from "next/image";
import Widget from "~components/widget";
import { useEffect, useRef, useState, useCallback } from "react";
import AnimationWrapper from "./AnimationWrapper";
import { useRouter } from "next/router";

const cards = [
  {
    title: "Help center",
    content:
      "Guides, FAQ and other useful info will be waiting for you our help center",
    logo: "/assets/images/widgets-cards/rainbow.jpg",
    link: "/help-center",
  },
  {
    title: "Beta version",
    content: (
      <div>
        Welcome to the beta test of our platform. If you have any difficulties,
        please contact us{" "}
        <span className="text-zinc-base">help@subclub.me</span>
      </div>
    ),
    logo: "/assets/images/widgets-cards/beta.jpg",
  },
  {
    title: "Subclub Storage",
    content:
      "Cloud storage for storing and sharing your files without compression.",
    logo: "/assets/images/widgets-cards/storage.jpg",
  },
];

const WidgetCarousel = () => {
  const [showing, setShowing] = useState(0);
  const interval = useRef();
  const { push } = useRouter();

  useEffect(() => {
    const intId = setInterval(() => {
      setShowing((sh) => (sh === cards.length - 1 ? 0 : sh + 1));
    }, 10000);

    interval.current = intId;

    return () => clearInterval(interval.current);
  });

  return (
    <>
      <div className="h-40 mt-5 mb-8">
        {cards.map((card, idx) => (
          <AnimationWrapper
            key={card.title}
            show={showing === idx}
            onClick={() => card.link && push(card.link)}
          >
            <div className={`${card.link ? "cursor-pointer" : ""} `}>
              <Widget title={card.title}>
                <div
                  className={
                    "h-20 flex items-center justify-start relative pb-1"
                  }
                >
                  <div className="w-[70%] font-medium text-[14px] leading-5 -mt-2 flex">
                    {card.content}
                  </div>
                  <div className="absolute -bottom-1 right-0">
                    <Image
                      width={80}
                      height={120}
                      src={card.logo}
                      quality={100}
                      objectFit="contain"
                    />
                  </div>
                </div>
              </Widget>
            </div>
          </AnimationWrapper>
        ))}
      </div>
      <div className="flex justify-center items-center w-full">
        {cards.map((card, idx) => (
          <div
            className={`h-2 w-2 m-1 rounded-full duration-200 cursor-pointer ${
              showing === idx ? "bg-zinc-900 opacity-50" : "bg-[#EAEAEA]"
            }`}
            key={card.title}
            onClick={() => setShowing(idx)}
          />
        ))}
      </div>
    </>
  );
};

export default WidgetCarousel;
