import React from "react";
import { useDispatch } from "react-redux";
import Button from "~components/ui/button";
import { setDonateModal } from "~store/modals";

const DonateButton = ({ address }) => {
  const dispatch = useDispatch();

  return (
    <Button
      onClick={() => dispatch(setDonateModal({ show: true, address }))}
      variant="rounded"
      className="@wh w-full lg:w-[140px] @bdc bg-white border border-zinc-900 @ttc text-zinc-900 hover:text-white hover:bg-zinc-900 @pg px-5 py-[15px] md:px-8 @mn mr-0"
    >
      Donate
    </Button>
  );
};

export default DonateButton;
