import ShareCardHeading from "./share-card-heading";
import SocialList from "./social-list";
import P from "~ui/typography/p";
import CopyText from "~ui/interactions/copy-text";

const ShareCard = ({ shareLink }) => (
  <div className="@tta text-center lg:w-[500px] @pg py-10 px-[50px] @dy flex @fxd flex-col @ani items-center bg-white rounded-2xl">
    <ShareCardHeading title={"Share user’s profile"} />
    <P
      className="@ftf font-montserrat @ttc text-zinc-base @we whitespace-nowrap @mn mb-3 @ftw font-medium @fts text-14px @leh leading-20px"
      variant="medium"
    >
      Share with
    </P>
    <SocialList shareLink={shareLink} />
    <P
      className="@ftf font-montserrat @ttc text-zinc-base @we whitespace-nowrap @mn mb-3 @ftw font-medium @fts text-14px @leh leading-20px"
      variant="medium"
    >
      Or share with link
    </P>
    {shareLink && (
      <CopyText
        title={shareLink}
        className={
          "!bg-stone-base !rounded-[10px] !p-3 !text-zinc-base !font-medium border border-[#DCDCDC] relative"
        }
        inputClassName={"!w-[93%] text-left"}
        btnColor={"zinc-base"}
        btnClassName={"!top-3 bg-stone-base"}
      />
    )}
  </div>
);

export default ShareCard;
