import ProfileInfo from "./profile-info";
import CardBox from "../../ui/card-box/index";
import Image from "next/image";
import Avatar from "~components/ui/avatar";
import { getSocialImagePath } from "~utils/vanilla/formatters/get-social-image-path";
import ReferralLink from "~components/referral-link";
import PlatformReferral from "~components/referral-link/PlatformReferral";

export default function ProfileHeader({
  profile,
  posts,
  contextProfile,
  disableButtons,
  isMyProfile,
}) {
  const preparedImage = profile?.image
    ? getSocialImagePath(profile.image)
    : null;

  const preparedCover = profile?.cover
    ? getSocialImagePath(profile.cover)
    : null;

  return (
    <CardBox className="@pg p-0 @ow overflow-hidden @mn mb-5 shadow-base @brw border-0">
      <div className="w-full p-4 md:p-8 relative flex justify-end h-[160px] md:h-[220px]">
        <Image
          priority={true}
          layout="fill"
          src={preparedCover ? preparedCover : "/assets/images/background.jpg"}
          className="object-cover"
        />
      </div>
      <div className="-mt-[100px] md:-mt-[120px] md:-mb-[60px]">
        <Avatar
          className="@brc !border-white @brw border-[5px] md:border-[6px] bg-white"
          src={preparedImage ? preparedImage : "/assets/images/avatar.jpg"}
        />
      </div>
      <div className="mt-4 mn:pt-10 md:pt-16 pb-7 px-6">
        <ProfileInfo
          profile={profile}
          posts={posts}
          isMyProfile={isMyProfile}
          disableButtons={disableButtons}
        />
      </div>
      <div className="block lg:hidden px-4">
        {profile && contextProfile ? (
          isMyProfile ? (
            <PlatformReferral
              contextProfile={contextProfile}
              profile={profile}
            />
          ) : (
            <ReferralLink contextProfile={contextProfile} profile={profile} />
          )
        ) : null}
      </div>
    </CardBox>
  );
}
