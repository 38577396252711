import P from "~ui/typography/p";
import CopyButton from "~ui/copy-button";

const CopyText = ({
  title,
  textToCopy = title,
  className,
  inputClassName,
  btnClassName,
  btnColor,
}) => {
  return (
    <div
      className={`flex items-center w-full justify-between rounded-full px-5 py-3 bg-white bg-opacity-10 text-white overflow-hidden ${className}`}
    >
      <div className={`w-9/12 md:w-10/12 overflow-scroll ${inputClassName}`}>
        <P className="whitespace-nowrap" variant="large">
          {title}
        </P>
      </div>
      <CopyButton
        containerClassName={`@pn absolute top-2.5 right-2 ${btnClassName}`}
        textToCopy={textToCopy}
        btnColor={btnColor}
      />
    </div>
  );
};

export default CopyText;
