import React from "react";
import { isMobile } from "react-device-detect";
import MobileModal from "~components/popups/mobile-modal";
import Modal from "~components/popups/modal";
import Button from "~components/ui/button";
import LocalIcons from "~components/ui/all-icons";
import ShareCard from "../share-card";

const ShareButton = (props) => {
  return isMobile ? (
    <MobileModal RenderCard={ShareCard} renderCardProps={props}>
      <Button
        variant="rounded"
        className="@wh w-full lg:w-[80px] @bdc bg-white border border-zinc-900 stroke-zinc-900 hover:stroke-white hover:bg-zinc-900 @pg py-[15px] @mn mr-0 flex justify-center items-center h-14"
      >
        <LocalIcons className="h-5 w-5 stroke-inherit" name="Share" />
      </Button>
    </MobileModal>
  ) : (
    <Modal
      RenderCard={ShareCard}
      renderCardProps={props}
      childContainerClasses={"@wh w-full lg:w-fit"}
      containerClasses={"@pg px-2 py-0 md:px-4 md:py-0"}
    >
      <Button
        variant="rounded"
        className="@wh w-full lg:w-[80px] @bdc bg-white border border-zinc-900 stroke-zinc-900 hover:stroke-white hover:bg-zinc-900 @pg py-[15px] @mn mr-0 flex justify-center items-center h-14"
      >
        <LocalIcons className="h-5 w-5 stroke-inherit" name="Share" />
      </Button>
    </Modal>
  );
};

export default ShareButton;
