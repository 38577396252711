import P from "~components/ui/typography/p";
import H3 from "~components/ui/typography/h3";

const ShareCardHeading = ({ title, subtitle }) => (
  <>
    <img
      className="object-cover h-10 mb-10"
      src="/assets/images/plane.png"
      alt="paper-plane"
    />
    {title ? (
      <H3 className="@ftf font-clash @fts text-26px @leh leading-32px @tta text-center @mn mb-10 font-semibold">
        {title}
      </H3>
    ) : null}
    {subtitle ? (
      <P className="@mn mx-auto @ftf font-family-inter @fts text-14px @leh leading-20px @ttc text-true-gray-850 dark:text-true-gray-450">
        {subtitle}
      </P>
    ) : null}
  </>
);

export default ShareCardHeading;
