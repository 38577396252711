import { transformResponseItem } from "~api";
import { profilePopulate } from "~api/utils";
import { backendApi } from "..";

export const referralLinksApi = backendApi.injectEndpoints({
  endpoints: (build) => ({
    getMyReferralLinks: build.query({
      query: (userId) => ({
        url: "referral-links",
        params: {
          filters: { user: userId },
          populate: {
            author: { populate: profilePopulate },
            user: { populate: profilePopulate },
          },
        },
      }),

      transformResponse: transformResponseItem,
    }),

    getReferralLink: build.query({
      query: ({ userId, authorId }) => ({
        url: "referral-links",
        params: {
          filters: { user: userId, author: authorId },
          populate: "*",
        },
      }),

      transformResponse: (response) =>
        response?.data?.length
          ? transformResponseItem(response?.data?.[0])
          : null,

      providesTags: (_result, _error, { authorId }) => [
        { type: "ReferralLink", id: authorId },
      ],
    }),

    createReferralLink: build.mutation({
      query: (authorId) => ({
        url: "referral-links",
        method: "POST",
        body: {
          data: {
            author: authorId,
          },
        },
      }),

      transformResponse: transformResponseItem,

      invalidatesTags: (_result, _error, authorId) => [
        { type: "ReferralLink", id: authorId },
      ],
    }),
  }),
});

export const {
  useGetMyReferralLinksQuery,
  useGetReferralLinkQuery,
  useCreateReferralLinkMutation,
} = referralLinksApi;
